import { useEffect, useState } from 'react';

import { IconDownload, IconTrash } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';

import {
  TemplateCategoryEnum,
  fetchDeleteTemplate,
  fetchGetTemplate,
  fetchSearchTemplates,
} from '@octopus/api';
import {
  DataGrid,
  DataGridSkeleton,
  FilterOptions,
  GridColDef,
  GridValueGetterParams,
  makeElementListFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { DataFetching } from '../../../modules/dataFetching';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { prepareDataGridSearchInput } from '../../../utils/dataGridUtils';
import { downloadFile } from '../../../utils/files';

import { DeleteConfirmationDialog } from './DeleteConfirmationDialog';
import { templateCategoryLabels } from './types';

type Props = {
  organizationId: string;
};

type TemplatesTableState = {
  totals:
    | {
        totalCount: number;
        current: number;
      }
    | undefined;
  filters: {
    categories: {
      name: string;
      count: number;
    }[];
  };
};

export function TemplatesTable({ organizationId }: Props) {
  const initialState: TemplatesTableState = {
    totals: undefined,
    filters: {
      categories: [],
    },
  };

  const [state, setState] = useState<TemplatesTableState>(initialState);

  const filters = useFilters(state.filters);
  const dataGridProps = useDataGrid({
    key: `templates-${organizationId}`,
    filters,
  });

  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(
    null,
  );
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const handleDownload = (templateId: string) => {
    setCurrentTemplateId(templateId);
    setIsDownloading(true);
    showSnackbar({
      isOpen: true,
      Message: 'Seu download começará em breve',
    });

    fetchGetTemplate({ pathParams: { organizationId, templateId } })
      .then((template) => {
        downloadFile(template.file.downloadUrl);
      })
      .catch((error) => {
        console.error(error);
        showSnackbar({
          isOpen: true,
          Message: 'Erro ao baixar arquivo',
          variant: 'error',
        });
      })
      .finally(() => {
        setIsDownloading(false);
        setCurrentTemplateId(null);
      });
  };

  const handleDelete = (templateId: string) => {
    setCurrentTemplateId(templateId);
    setIsDeleting(true);

    return fetchDeleteTemplate({ pathParams: { organizationId, templateId } })
      .then(() => {
        setTimeout(() => {
          showSnackbar({
            isOpen: true,
            Message: 'Template excluído com sucesso',
          });
          setShowDeleteConfirmationDialog(false);
          templatesQuery.refetch({ stale: true });
          setIsDeleting(false);
          setCurrentTemplateId(null);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        showSnackbar({
          isOpen: true,
          Message: 'Erro ao excluir template',
          variant: 'error',
        });
        setIsDeleting(false);
        setCurrentTemplateId(null);
      });
  };

  const disableActions = isDownloading || isDeleting;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.name;
      },
    },
    {
      field: 'category',
      headerName: 'Categoria',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return templateCategoryLabels[
          params.row.category as TemplateCategoryEnum
        ];
      },
    },
    {
      field: 'download',
      headerName: 'Baixar template',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.templateId;
      },
      renderCell: ({ value }) => {
        const templateId = value;

        return (
          <LoadingButton
            loading={isDownloading && currentTemplateId === templateId}
            onClick={() => handleDownload(templateId)}
            color="primary"
            variant="text"
            sx={{ mx: '6px' }}
          >
            <IconDownload
              width={20}
              height={20}
              className={disableActions ? 'disabled' : 'main'}
            />
          </LoadingButton>
        );
      },
    },
    {
      field: 'delete',
      headerName: 'Excluir template',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.templateId;
      },
      renderCell: ({ value }) => {
        const templateId = value;
        return (
          <LoadingButton
            loading={isDeleting && currentTemplateId === templateId}
            onClick={() => {
              setCurrentTemplateId(templateId);
              setShowDeleteConfirmationDialog(true);
            }}
            color="primary"
            variant="text"
            sx={{ mx: '6px' }}
          >
            <IconTrash
              width={20}
              height={20}
              className={disableActions ? 'disabled' : 'error'}
            />
          </LoadingButton>
        );
      },
    },
  ];

  const { sortingProps, paginationProps } = dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);

  searchInput.counting = {
    filtered: {
      byProp: {
        category: Object.keys(templateCategoryLabels),
      },
    },
    unfiltered: {
      byProp: {
        category: Object.keys(templateCategoryLabels),
      },
    },
  };

  searchInput.filtering = {
    ...searchInput.filtering,
    elements: {
      ...searchInput.filtering.elements,
      active: ['true'],
    },
  };

  const queryKey = [organizationId, searchInput, paginationProps];
  const templatesQuery = useQuery({
    queryKey,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return fetchSearchTemplates({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: searchInput,
      });
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (!templatesQuery.isError && templatesQuery.data) {
      setState((state) => ({
        totals: {
          current: templatesQuery.data.total,
          // TODO: remove the archived rows from opensearch
          totalCount: templatesQuery.data.unfilteredTotal,
        },
        filters: {
          ...state.filters,
          ...templatesQuery.data.filters,
        },
      }));
    }
  }, [templatesQuery.isError, templatesQuery.data]);

  return (
    <>
      <DataFetching
        fetchResult={templatesQuery}
        Loading={() => {
          return <DataGridSkeleton columnsCount={3} rowsCount={5} />;
        }}
        Data={({ data }) => {
          const response = data;
          return (
            <Box mt={2}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.templateId}
                  emptyMessage={'Nenhum template encontrado'}
                  rows={response.data}
                  columns={columns}
                />
              ) : null}
            </Box>
          );
        }}
      />
      <DeleteConfirmationDialog
        open={showDeleteConfirmationDialog}
        isDeleting={isDeleting}
        onClose={() => setShowDeleteConfirmationDialog(false)}
        onConfirm={() => handleDelete(currentTemplateId)}
      />
    </>
  );
}

function useFilters(_props: TemplatesTableState['filters']): FilterOptions {
  return [
    makeElementListFilter({
      label: 'Categoria',
      propertyToFilter: 'category',
      elements: Object.keys(templateCategoryLabels),
      labels: Object.entries(templateCategoryLabels).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, string>,
      ),
    }),
  ];
}
