import { v4 } from 'uuid';

import {
  ContractUpdate,
  DepartmentSummary,
  fetchPutContract,
  fetchSearchAllContracts,
} from '@octopus/api';
import { translate } from '@octopus/i18n';

import { pollUntil } from '../../../../../utils';
import { MultiEditResult } from '../../../MultiEdit';

import { AddContractEntry } from './types';

export async function changeDepartmentForContracts(
  contracts: AddContractEntry[],
  newDepartment: DepartmentSummary,
  effectiveDate: string,
  incrementProgress: () => void,
) {
  const idempotencyKey = v4();
  const results: MultiEditResult<AddContractEntry>[] = [];
  for (const contract of contracts) {
    try {
      await changeDepartmentForContract(
        newDepartment.organizationId,
        contract,
        newDepartment.departmentId,
        effectiveDate,
        idempotencyKey,
      );
      results.push({ item: contract, success: true, error: undefined });
    } catch (err) {
      let message =
        'Erro inesperado. Tente novamente ou entre em contato com o suporte da Tako.';
      if (
        err instanceof Object &&
        'stack' in err &&
        'details' in err.stack &&
        err.stack.details instanceof Object
      ) {
        Object.keys(err.stack.details).forEach((key) => {
          message = translate(key, 'pt');
        });
      }
      results.push({
        item: contract,
        success: false,
        error: message,
      });
    } finally {
      incrementProgress();
    }
  }
  const success = results.filter((result) => result.success);
  if (success.length > 0) {
    const toPoll = success[success.length - 1];
    await pollUntilContractDepartmentIsUpdated(
      newDepartment.organizationId,
      toPoll.item.contractId,
      newDepartment.departmentId,
    );
  }
  return results;
}

export async function changeDepartmentForContract(
  organizationId: string,
  contract: { contractId: string; contractType: 'br:clt' | 'br:pj' },
  newDepartmentId: string,
  effectiveDate: string,
  idempotencyKey = v4(),
) {
  await fetchPutContract({
    pathParams: {
      organizationId,
      contractId: contract.contractId,
    },
    body: {
      br:
        contract.contractType === 'br:clt'
          ? {
              trabalho: {
                departmentId: newDepartmentId,
              },
            }
          : {
              prestador: {
                posicao: {
                  departmentId: newDepartmentId,
                },
              },
            },
      contractType: contract.contractType,
      effectiveDate: effectiveDate,
    } as ContractUpdate,
    headers: {
      'x-idempotency-key': idempotencyKey,
    },
  });
}

export async function pollUntilContractDepartmentIsUpdated(
  organizationId: string,
  contractId: string,
  departmentId: string,
) {
  try {
    await pollUntil({
      action: () =>
        fetchSearchAllContracts({
          pathParams: {
            organizationId,
          },
          body: {
            filtering: {
              elements: {
                contractId: [contractId],
                departmentId: [departmentId],
              },
            },
          },
        }),
      assertion: ({ total }) => total > 0,
      intervalMillis: 300,
      timeoutSeconds: 10,
    });
  } catch (err) {
    console.warn(err);
  }
}
