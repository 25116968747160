import React, { useContext, useMemo, useState } from 'react';

import { IconCirclePlus } from '@tabler/icons-react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ContractBRPjEntryPrestador, fetchGetAllJobTitles } from '@octopus/api';

import { AppContext } from '../../../../../app/context';
import { pollUntil } from '../../../../../utils';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { CreateJobTitleDrawer } from '../../../jobTitles/CreateJobTitleDrawer';
import { Record, RecordEntry } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type PrestadorDeServicoRecordData = {
  organizationId: string;
  workerId: string;
  emailCorp: string;
  prestador: ContractBRPjEntryPrestador;
};

export type PrestadorDeServicoRecordProps =
  BaseRecordProps<PrestadorDeServicoRecordData> & {
    companyId: string;
  };

export function PrestadorDeServicoRecord(props: PrestadorDeServicoRecordProps) {
  const {
    companyId,
    data: {
      organizationId,
      workerId,
      emailCorp,
      prestador: { posicao, gestao },
    },
  } = props;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const { appContext, refetchCompanyData } = useContext(AppContext);
  const { showSnackbar } = useSnackbar();
  const [isCreateJobDrawerOpen, setIsCreateJobDrawerOpen] = useState(false);

  const costCenters = appContext?.company?.costCenters;
  const costCenter = costCenters?.find(({ id }) => gestao?.costCenterId === id);

  const departments = appContext?.company?.departments;
  const department = departments?.find(
    ({ id }) => posicao?.departmentId === id,
  );

  const [jobTitles, setJobTitles] = useState<
    | {
        jobTitleId: string;
        name: string;
        occupationCode?: string;
        active: boolean;
      }[]
    | undefined
  >(appContext?.company?.jobTitles.map((jbt) => jbt.summary));
  const jobTitle = jobTitles?.find(
    ({ jobTitleId }) => posicao.jobTitleId === jobTitleId,
  );
  const maxJobTitleCode = useMemo(
    () =>
      Math.max(
        ...(appContext?.company?.jobTitles?.map((jbt) => jbt.summary.code) ?? [
          0,
        ]),
      ),
    [appContext?.company?.jobTitles?.length],
  );

  return (
    <Record title="Prestador de serviço" edit={editRecordProps}>
      <RecordEntry
        label="Matrícula / Identificador"
        edit={{
          type: 'text',
          editing,
          value: formData.workerId,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              workerId: value,
            })),
          hasError: hasError('workerId'),
        }}
      >
        {workerId}
      </RecordEntry>
      <RecordEntry
        label="Cargo"
        isLoading={!jobTitle}
        edit={{
          type: 'options',
          editing,
          value: formData.prestador.posicao.jobTitleId,
          disabled: !jobTitles,
          options: jobTitles
            ?.filter(
              ({ active, jobTitleId }) =>
                active || jobTitleId === jobTitle.jobTitleId,
            )
            ?.map(({ jobTitleId, name }) => ({
              value: jobTitleId,
              label: name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  jobTitleId: value,
                },
              },
            })),
          hasError: hasError('br/prestador/posicao/jobTitleId'),
          action: {
            Icon: <IconCirclePlus size={16} color="blue" />,
            label: 'Criar novo cargo',
            onClick: () => {
              setIsCreateJobDrawerOpen(true);
            },
          },
        }}
      >
        {jobTitle?.name}
      </RecordEntry>
      <RecordEntry
        label="Departamento"
        edit={{
          type: 'options',
          editing,
          disabled: !departments,
          value: formData.prestador.posicao?.departmentId,
          options: [
            { label: 'N/A', value: null },
            ...(departments
              ?.filter(
                ({ summary }) =>
                  summary.active ||
                  summary.departmentId === posicao?.departmentId,
              )
              ?.map(({ id, name }) => ({
                value: id,
                label: name,
              })) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  departmentId: value,
                },
              },
            })),
          hasError: hasError('br/prestador/posicao/departmentId'),
        }}
      >
        {department?.name}
      </RecordEntry>
      <RecordEntry
        label="Centro de custo"
        edit={{
          type: 'options',
          editing,
          disabled: !costCenters,
          value: formData.prestador.gestao?.costCenterId,
          options: [
            { label: 'N/A', value: null },
            ...(costCenters
              ?.filter(
                ({ summary }) =>
                  summary.active ||
                  summary.costCenterId === gestao?.costCenterId,
              )
              ?.map(({ id, name }) => ({
                value: id,
                label: name,
              })) ?? []),
          ],
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                gestao: {
                  ...data.prestador.gestao,
                  costCenterId: value,
                  nomeCentroCusto:
                    costCenters?.find(({ id }) => id === value)?.summary
                      ?.name ?? null,
                  codigoCentroCusto:
                    costCenters?.find(({ id }) => id === value)?.summary
                      ?.code ?? null,
                },
              },
            })),
          hasError: hasError('br/prestador/gestao/costCenterId'),
        }}
      >
        {costCenter?.name}
      </RecordEntry>
      <RecordEntry
        label="Email corporativo"
        edit={{
          type: 'text',
          editing,
          value: formData.emailCorp,
          onChange: (value) =>
            updateData((data) => ({
              ...data,
              emailCorp: value,
            })),
          hasError: hasError('br/emailCorp'),
        }}
      >
        {emailCorp}
      </RecordEntry>
      {isCreateJobDrawerOpen && (
        <CreateJobTitleDrawer
          organizationId={organizationId}
          companies={appContext?.company?.companies?.map((cmp) => cmp.summary)}
          maxCode={maxJobTitleCode}
          open={isCreateJobDrawerOpen}
          onClose={() => setIsCreateJobDrawerOpen(false)}
          onSuccess={async (jobTitle) => {
            setJobTitles((jbts) => [...jbts, jobTitle]);
            updateData((data) => ({
              ...data,
              prestador: {
                ...data.prestador,
                posicao: {
                  ...data.prestador.posicao,
                  jobTitleId: jobTitle.jobTitleId,
                },
              },
            }));
            setIsCreateJobDrawerOpen(false);
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Cargo criado com sucesso',
              StartAdornment: <CheckCircleIcon />,
            });
            try {
              await pollUntil({
                action: () =>
                  fetchGetAllJobTitles({
                    pathParams: {
                      organizationId,
                    },
                    queryParams: {
                      elementFilters: JSON.stringify({
                        jobTitleId: [jobTitle.jobTitleId],
                      }),
                    },
                  }),
                assertion: (list) => list.data.length === 1,
                intervalMillis: 500,
                timeoutSeconds: 5,
              });
            } catch (_) {
              console.warn('Failed to poll updates to list');
            }
            await refetchCompanyData();
          }}
          requireContractType={'br:pj'}
          requireCompany={companyId}
        />
      )}
    </Record>
  );
}
