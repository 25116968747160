import { cnpj } from 'cpf-cnpj-validator';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  AdmissionDraftContractBRCltEntry,
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
  ContractBRCltCodigoCategoria,
  ContractBRCltContratoRegimeParcial,
  ContractBRCltEntryAprendizDireto,
  ContractBRCltIndAdmissao,
  ContractBRCltNaturezaEstagio,
  ContractBRCltNivelEstagio,
  ContractBRCltTipoAdmissao,
  ContractBRCltTipoContrato,
  ContractBRCltTipoJornada,
  ContractBRCltTipoRegimeJornada,
  ContractBRCltTipoRegimePrevidenciario,
  ContractBRCltTipoRegimeTrabalhista,
  ContractBRCltUnidadeSalarioFixo,
} from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';
import {
  admissionDraftFormSteps,
  getAdmissionCostCenterId,
} from '@octopus/onboarding-types';

import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  let formState = {} as AdmissionFormState;
  if (!admissionDraftEntry) {
    return {};
  }

  const companyId = admissionDraftEntry?.companyId;
  const br = admissionDraftEntry.br as AdmissionDraftContractBRCltEntry;
  const { regime, jornada, duracao, vinculo, trabalho, remuneracao, estagio } =
    br || {};
  const aprendiz = br?.aprendiz as ContractBRCltEntryAprendizDireto;

  let duracaoContr = null;
  if (duracao?.dtTerm && regime?.dtAdm) {
    const dtTerm = dayjs(duracao?.dtTerm);
    const dtAdm = dayjs(regime?.dtAdm);

    duracaoContr = dtTerm.diff(dtAdm, 'days') + 1;
  }

  if (aprendiz) {
    formState = {
      ...formState,
      cnpjEntQual: formatCNPJ(aprendiz.cnpjEntQual),
      razaoSocialEntQual: aprendiz.razaoSocialEntQual,
    };
  }

  return {
    ...formState,
    company: {
      companyId,
      legalEntity: admissionDraftEntry?.legalEntityId,
    },
    matricula: vinculo?.matricula,
    emailCorp: vinculo?.emailCorp,
    tpRegTrab: (vinculo?.tpRegTrab ?? 1) as ContractBRCltTipoRegimeTrabalhista,
    tpRegPrev: (vinculo?.tpRegPrev ??
      1) as ContractBRCltTipoRegimePrevidenciario,

    cargo: trabalho?.jobTitleId,
    cargoConfianca: {
      CBOFuncao: trabalho?.CBOFuncao,
      nmFuncao: trabalho?.nmFuncao,
      enabled: trabalho?.CBOFuncao != null ? 'true' : 'false',
    },

    departmentId: trabalho?.departmentId,
    codCateg: trabalho?.codCateg,

    centro_de_custo: getAdmissionCostCenterId(admissionDraftEntry),

    admissionDate: regime?.dtAdm,
    tpAdmissao: regime?.tpAdmissao ?? (1 as ContractBRCltTipoAdmissao),
    tpRegJor: regime?.tpRegJor,
    cnpjSindCategProf: formatCNPJ(regime?.cnpjSindCategProf),
    indAdmissao: {
      defaultValue: regime?.indAdmissao,
    },
    nrProcTrab: regime?.nrProcTrab,

    tpJornada: jornada?.tpJornada,
    tmpParc: jornada?.tmpParc || (0 as ContractBRCltContratoRegimeParcial),
    descJorn: jornada?.descJorn,
    qtdHrsSem: jornada?.qtdHrsSem,
    horNoturno: jornada?.horNoturno,

    tpContr: {
      type: duracao?.tpContr,
      duracaoContr,
      dtTerm: duracao?.dtTerm,
      dtPerExpTerm: duracao?.dtPerExpTerm,
      dtPerExpProrTerm: duracao?.dtPerExpProrTerm,
      clauAssec: duracao?.clauAssec != null ? `${duracao?.clauAssec}` : '',
    },
    salary: remuneracao?.vrSalFx,
    unidadeBaseCalculo: remuneracao?.undSalFixo,

    natEstagio: estagio?.natEstagio as ContractBRCltNaturezaEstagio,
    nivEstagio: Number(estagio?.nivEstagio) as ContractBRCltNivelEstagio,
    areaAtuacao: estagio?.areaAtuacao,
    nrApol: estagio?.nrApol,
    cnpjAgntInteg: formatCNPJ(estagio?.cnpjAgntInteg),
    cnpjInstEnsino: formatCNPJ(estagio?.instEnsino?.cnpjInstEnsino),
    dtPrevTerm: estagio?.dtPrevTerm,
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  const [indAdmissao, nrProcTrab] = Object.values(
    formState.indAdmissao || {},
  ) as string[];

  const nrProcTrabObj = nrProcTrab ? { nrProcTrab: nrProcTrab } : {};
  const indAdmissaoObj = {
    indAdmissao: Number(indAdmissao) as ContractBRCltIndAdmissao,
  };

  const cargoConfianca = formState.cargoConfianca?.enabled
    ? {
        CBOFuncao: formState.cargoConfianca?.CBOFuncao,
        nmFuncao: formState.cargoConfianca?.nmFuncao,
      }
    : {};

  switch (currentStepName) {
    case admissionDraftFormSteps.estagio_info: {
      body.br = {
        estagio: {
          natEstagio: formState.natEstagio as ContractBRCltNaturezaEstagio,
          nivEstagio: Number(formState.nivEstagio) as ContractBRCltNivelEstagio,
          areaAtuacao: formState.areaAtuacao,
          ...(formState.nrApol && { nrApol: formState.nrApol }),
          cnpjAgntInteg: cnpj.strip(formState.cnpjAgntInteg),
          instEnsino: { cnpjInstEnsino: cnpj.strip(formState.cnpjInstEnsino) },
          dtPrevTerm: formState.dtPrevTerm, // todo: calculate the proper prop if it is already filled
        },
      };
      break;
    }
    case admissionDraftFormSteps.jovem_aprendiz_info: {
      if (formState.cnpjEntQual || formState.razaoSocialEntQual) {
        body.br = {
          aprendiz: {
            cnpjEntQual: cnpj.strip(formState.cnpjEntQual),
            razaoSocialEntQual: formState.razaoSocialEntQual,
            indAprend: 'direta',
          },
        };
      }
      break;
    }
    case admissionDraftFormSteps.profissional: {
      body.workerId = formState.workerId;
      body.br = {
        trabalho: {
          codCateg: Number(
            formState.codCateg ?? '101',
          ) as ContractBRCltCodigoCategoria,
          departmentId: formState.departmentId,
          jobTitleId: formState.cargo,
          ...cargoConfianca,
        },
        vinculo: {
          matricula: formState.matricula,
          ...(formState.emailCorp && { emailCorp: formState.emailCorp }),
          tpRegPrev: Number(
            formState.tpRegPrev ?? '1',
          ) as ContractBRCltTipoRegimePrevidenciario,
          tpRegTrab: Number(
            formState.tpRegTrab ?? '1',
          ) as ContractBRCltTipoRegimeTrabalhista,
        },
        gestao: {
          costCenterId: formState.centro_de_custo,
        },
      };
      break;
    }
    case admissionDraftFormSteps.regime_trabalho: {
      body.br = {
        vinculo: {
          matricula: formState.matricula,
          ...(formState.emailCorp && { emailCorp: formState.emailCorp }),
          tpRegPrev: Number(
            formState.tpRegPrev,
          ) as ContractBRCltTipoRegimePrevidenciario,
          tpRegTrab: Number(
            formState.tpRegTrab,
          ) as ContractBRCltTipoRegimeTrabalhista,
        },
        regime: {
          dtAdm: formState.admissionDate,
          tpAdmissao: Number(formState.tpAdmissao) as ContractBRCltTipoAdmissao,
          ...indAdmissaoObj,
          ...nrProcTrabObj,
          tpRegJor: Number(
            formState.tpRegJor ?? '1',
          ) as ContractBRCltTipoRegimeJornada,
          natAtividade: 1,
        },
        trabalho: {
          codCateg: Number(formState.codCateg) as ContractBRCltCodigoCategoria,
          departmentId: formState.departmentId,
          jobTitleId: formState.cargo,
          ...cargoConfianca,
        },
      };
      break;
    }
    case admissionDraftFormSteps.duracao: {
      const { type, duracaoContr, clauAssec, probationPeriod } =
        formState.tpContr;

      let dtTerm = null;
      let clausula = null;
      let dtPerExpTerm = null;
      let dtPerExpProrTerm = null;

      dayjs.extend(utc);
      if (type !== '1') {
        clausula = { clauAssec: clauAssec === 'true' };

        if (duracaoContr != null && formState.admissionDate) {
          dtTerm = dayjs(new Date(formState.admissionDate))
            .add(duracaoContr, 'days')
            .format('YYYY-MM-DD');
        }

        if (probationPeriod) {
          const [probationPeriodDays, probationPeriodExtensionDays] =
            probationPeriod.split('+');
          dtPerExpTerm = dayjs
            .utc(new Date(formState.admissionDate))
            .add(probationPeriodDays - 1, 'days')
            .format('YYYY-MM-DD');
          dtTerm = dtPerExpTerm;
          if (probationPeriodExtensionDays) {
            dtPerExpProrTerm = dayjs
              .utc(new Date(dtPerExpTerm))
              .add(probationPeriodExtensionDays, 'days')
              .format('YYYY-MM-DD');
          }
        }

        if (formState.tpContr?.dtTerm) {
          dtTerm = formState.tpContr?.dtTerm;
        }
      }

      body.br = {
        duracao: {
          tpContr: Number(type == 1 ? 1 : 2) as ContractBRCltTipoContrato,
          dtPerExpTerm,
          dtPerExpProrTerm,
          ...(dtTerm && { dtTerm }),
          ...clausula,
        },
      };
      break;
    }
    case admissionDraftFormSteps.jornada: {
      body.br = {
        regime: {
          tpRegJor: Number(
            formState.tpRegJor,
          ) as ContractBRCltTipoRegimeJornada,
          dtAdm: formState.admissionDate,
          tpAdmissao: Number(formState.tpAdmissao) as ContractBRCltTipoAdmissao,
          ...indAdmissaoObj,
          ...nrProcTrabObj,
          natAtividade: 1,
        },
        jornada: {
          tpJornada: Number(formState.tpJornada) as ContractBRCltTipoJornada,
          tmpParc: Number(
            formState.tmpParc,
          ) as ContractBRCltContratoRegimeParcial,
          descJorn: formState.descJorn,
          qtdHrsSem: Number(formState.horasFieldset?.qtdHrsSem ?? '0'),
          horNoturno: formState.horasFieldset?.horNoturno === 'on',
        },
      };
      break;
    }
    case admissionDraftFormSteps.sindicato: {
      body.br = {
        regime: {
          tpRegJor: Number(
            formState.tpRegJor,
          ) as ContractBRCltTipoRegimeJornada,
          dtAdm: formState.admissionDate,
          tpAdmissao: Number(formState.tpAdmissao) as ContractBRCltTipoAdmissao,
          indAdmissao: Number(indAdmissao) as ContractBRCltIndAdmissao,
          ...nrProcTrabObj,
          natAtividade: 1,
          cnpjSindCategProf: cnpj.strip(formState.cnpjSindCategProf),
        },
      };
      break;
    }

    case admissionDraftFormSteps.remuneracao: {
      body.br = {
        remuneracao: {
          vrSalFx: parseFloat(formState.salary.replace(',', '.') ?? '0'),
          undSalFixo: Number(
            formState.unidadeBaseCalculo,
          ) as ContractBRCltUnidadeSalarioFixo,
        },
      };
      break;
    }

    default:
      return null;
  }

  return body;
};

export const cltMapper = { getUpdateInputFromFormState, getFormStateFromEntry };
