import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MoreVert } from '@mui/icons-material';
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  SxProps,
  Typography,
} from '@mui/material';

import { useDOMElement } from '../useDOMElement';

type ActionMenuProps = {
  menuItems: ActionMenuItem[];
  variant?: 'button' | 'inline-hidden' | 'inline-visible';
  sx?: SxProps;
};

export type ActionMenuItem = {
  isDisabled?: boolean | (() => unknown);
  isPreventingClose?: boolean | (() => unknown);
  label: string;
  href?: string;
  onClick?: (...args: unknown[]) => unknown | void;
  color?: string;
  render?: (props?: {
    sx?: SxProps;
    key: React.Attributes['key'];
  }) => React.ReactNode;
};

export function ActionMenu(props: ActionMenuProps) {
  const navigate = useNavigate();
  const menuItemsToRender = props.menuItems.filter(Boolean);

  const isClosePrevented = Boolean(
    menuItemsToRender.filter((item) =>
      Boolean(
        typeof item.isPreventingClose === 'function'
          ? item.isPreventingClose.call(item)
          : item.isPreventingClose,
      ),
    ).length,
  );

  const [open, setOpen] = useState(isClosePrevented);

  const { element: menuButtonElement, ref: menuButtonElementRef } =
    useDOMElement();

  const variants = {
    button: props.variant === 'button',
    hidden: !('variant' in props) || props.variant === 'inline-hidden',
    visible: props.variant === 'inline-visible',
  };

  if (menuItemsToRender.length === 0) return null;

  return (
    <Box display="flex" justifyContent="flex-end" sx={props.sx}>
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuButtonElementRef}
        sx={(theme) => ({
          boxSizing: 'border-box',
          height: '32px',
          width: '32px',
          ...(variants.button && {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.strokes.light,
          }),
          borderRadius: '8px',
          padding: '8px',
          ...((!variants.hidden || open) && {
            '--ActionMenu-opacity': 1,
          }),
          opacity: 'var(--ActionMenu-opacity, 1)',
          m: 'var(--ActionMenu-button-margin, 0)',
        })}
      >
        <MoreVert
          fontSize="inherit"
          sx={{
            height: 'auto',
            width: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuButtonElement}
        onClick={(event) => event.stopPropagation()}
        onClose={() => !isClosePrevented && setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        {...(isClosePrevented && {
          transitionDuration: {
            appear: 0,
            enter: 0,
            exit: 0,
          },
        })}
        elevation={1}
        sx={{
          fontSize: '14px',
          transform: 'translateY(-5px) translateX(5px)',
          '.MuiPaper-root': {
            padding: '4px',
            minWidth: '9.9215em',
          },
        }}
      >
        {menuItemsToRender.map((menuItem) => {
          let menuItemEventHandlerProps: Pick<
            React.ComponentProps<typeof MenuItem>,
            'onClick'
          > = null;
          if (menuItem.onClick) {
            menuItemEventHandlerProps = {
              onClick: (event) => {
                event.preventDefault();
                event.stopPropagation();
                menuItem.onClick();
              },
            };
          } else if (menuItem.href) {
            menuItemEventHandlerProps = {
              onClick: (_event) => navigate(menuItem.href),
            };
          }

          const menuItemContent =
            'render' in menuItem ? (
              menuItem?.render?.({
                sx: {
                  my: 1,
                  mx: 1.5,
                },
                key: menuItem.label,
              })
            ) : (
              <Typography
                variant="body2"
                key={menuItem.label}
                sx={{
                  my: 1,
                  mx: 1.5,
                  color: menuItem.color,
                }}
              >
                {menuItem.label}
              </Typography>
            );

          const isDisabled = Boolean(
            typeof menuItem.isDisabled === 'function'
              ? menuItem.isDisabled.call(menuItem)
              : menuItem.isDisabled,
          );

          return (
            <MenuItem
              key={menuItem.label}
              color="secondary"
              {...menuItemEventHandlerProps}
              disabled={isDisabled}
              sx={{
                p: 0,
                borderRadius: '4px',
              }}
            >
              {menuItemContent}
            </MenuItem>
          );
        })}
      </Popover>
    </Box>
  );
}
