import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import {
  AddOrClearFiltersButton,
  EnableNewFilterPopover,
  EnabledFilters,
  FilterOptions,
  FilteringProps,
  useFiltering,
} from './filtering';
import {
  PaginationOptions,
  PaginationProps,
  TablePaginationFooter,
  usePagination,
} from './pagination';
import { SearchBar, SearchOptions, SearchProps, useSearch } from './searching';
import { SelectionProps, ToggleItemCheckbox, useSelection } from './selection';
import {
  DataGridHead,
  SortingInput,
  SortingProps,
  useSorting,
} from './sorting';

function DataGridCell<ObjectType extends GridValidRowModel>({
  column,
  row,
}: {
  column: GridColDef;
  row: ObjectType;
}) {
  const value = column.valueGetter
    ? column.valueGetter({
        row,
        field: column.field,
      } as unknown as GridValueGetterParams)
    : row[column.field];

  const formattedValue = column.valueFormatter
    ? column.valueFormatter({
        value,
        field: column.field,
      } as GridValueFormatterParams<ObjectType>)
    : value;

  const cellClassName =
    typeof column.cellClassName === 'string' ? column.cellClassName : '';
  return (
    <TableCell key={column.field} className={cellClassName}>
      {column.renderCell
        ? column.renderCell({
            formattedValue,
            value,
            row,
            field: column.field,
          } as unknown as GridRenderCellParams<ObjectType>)
        : value}
    </TableCell>
  );
}

export function DataGridToolbar({
  filters,
  searchProps,
  filteringProps,
  totals,
  searchPlaceholder,
  typeOfResultLabel = 'colaboradores',
  children,
  sx,
  hideFilters = false,
  searchBarSx,
}: React.PropsWithChildren & {
  filters: FilterOptions;
  searchProps: Omit<ReturnType<typeof useSearch>, 'rows'>;
  filteringProps: Omit<ReturnType<typeof useFiltering>, 'rows'>;
  totals?: {
    current: number;
    all: number;
  };
  searchPlaceholder?: string;
  typeOfResultLabel?: string;
  sx?: SxProps<Theme>;
  hideFilters?: boolean;
  searchBarSx?: SxProps<Theme>;
}) {
  return (
    <Box sx={sx}>
      <Box display="flex" alignItems="center" data-testid="data-table-toolbar">
        <SearchBar
          searchProps={searchProps}
          placeholder={searchPlaceholder}
          hideFilters={hideFilters}
          sx={searchBarSx}
        />
        {!hideFilters && (
          <Box ml={1}>
            <AddOrClearFiltersButton filteringProps={filteringProps} />
            <EnableNewFilterPopover
              filters={filters}
              filteringProps={filteringProps}
            />
          </Box>
        )}
        {totals && (
          <Typography ml={2} variant="caption">
            Mostrando{' '}
            <Typography variant="caption" fontWeight="bold">
              {totals.current}
            </Typography>{' '}
            de{' '}
            <Typography variant="caption" fontWeight="bold">
              {totals.all}
            </Typography>{' '}
            {typeOfResultLabel}
          </Typography>
        )}
        {children}
      </Box>
      {!hideFilters && (
        <EnabledFilters filters={filters} filteringProps={filteringProps} />
      )}
    </Box>
  );
}

export type DataGridProps = {
  sortingProps: SortingProps;
  filteringProps: FilteringProps;
  searchProps: SearchProps;
  paginationProps: PaginationProps;
  selectionProps: SelectionProps;
};

export function useDataGrid({
  filters = [],
  pagination,
  sorting,
  search,
  key,
}: {
  filters?: FilterOptions;
  pagination?: PaginationOptions;
  sorting?: SortingInput;
  search?: SearchOptions;
  key?: string;
}): DataGridProps {
  const sortingProps = useSorting(sorting || {});
  const filteringProps = useFiltering({
    filters,
    key,
  });
  const searchProps = useSearch(search);
  const paginationProps = usePagination(pagination);

  const selectionProps = useSelection();

  useEffect(() => {
    paginationProps.handleChangePage(null, 0);
    selectionProps.clear();
  }, [searchProps.searchTerm, filteringProps.filtersState]);

  return {
    sortingProps,
    filteringProps,
    searchProps,
    paginationProps,
    selectionProps,
  };
}

export function DataGrid<ObjectType extends GridValidRowModel>({
  rows,
  sortingProps,
  paginationProps,
  columns,
  onRowClick,
  getRowId,
  getRowSx = () => ({}),
  getHeadSx = () => ({}),
  rowCallToAction = null,
  onlyShowContextMenuOnHover = false,
  totalRowCount,
  emptyMessage = 'Nenhuma entrada encontrada.',
  selectionProps = undefined,
  onRowHover = undefined,
  getRowLink = undefined,
  hoverEffect = true,
}: {
  rows: ObjectType[];
  columns: GridColDef[];
  getRowId: (row: ObjectType) => string | number;
  onRowClick?: (params: { row: ObjectType }) => void;
  getRowSx?: (row: ObjectType) => object;
  getHeadSx?: () => object;
  filters?: FilterOptions;
  rowCallToAction?: ReactNode | ((row: ObjectType) => ReactNode);
  onlyShowContextMenuOnHover?: boolean;
  totalRowCount: number;
  emptyMessage?: string | ReactNode;
  selectionProps?: DataGridProps['selectionProps'];
  onRowHover?: {
    enter: (row: ObjectType) => void;
    exit: (row: ObjectType) => void;
  };
  getRowLink?: (row: ObjectType) => string;
  hoverEffect?: boolean;
} & Pick<DataGridProps, 'sortingProps' | 'paginationProps'>) {
  return (
    <Box width="100%">
      {rows?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
          maxHeight="384px"
          width="100%"
        >
          <Typography
            fontSize="12px"
            fontStyle="italic"
            color="#616161"
            fontWeight="500"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {emptyMessage}
          </Typography>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'none', mt: 1 }}
          data-testid="data-table"
        >
          <Box
            width="100%"
            sx={{
              overflowX: 'auto',
            }}
          >
            <Table
              sx={{
                tableLayout: 'auto',
                minWidth: '540px',
                overflow: 'hidden',
                '& th:first-of-type, & td:first-of-type': {
                  pl: 4,
                },
                '& th:last-of-type, & td:last-of-type': {
                  pr: 4,
                },
              }}
            >
              <DataGridHead
                columns={columns}
                extraColumns={rowCallToAction ? 1 : 0}
                selectionProps={selectionProps}
                getHeadSx={getHeadSx}
                {...(sortingProps as SortingProps & { order: 'desc' | 'asc' })}
              />
              <TableBody
                sx={{
                  '& tr:last-of-type': {
                    '& td': {
                      border: 'none',
                    },
                  },
                }}
              >
                {rows?.map((row) => (
                  <TableRow
                    component={getRowLink ? Link : 'div'}
                    to={getRowLink?.(row) || ''}
                    hover={hoverEffect}
                    sx={{
                      cursor: hoverEffect ? 'pointer' : undefined,
                      textDecoration: 'none',
                      ...getRowSx(row),
                      ...(onlyShowContextMenuOnHover && {
                        '& td.context-menu-cell': {
                          opacity: 0,
                        },
                        '&:hover, &:focus, &:active': {
                          '& td.context-menu-cell': {
                            opacity: 1,
                          },
                        },
                      }),
                    }}
                    key={getRowId(row)}
                    onClick={onRowClick ? () => onRowClick({ row }) : undefined}
                    data-testid="data-table-row"
                    onMouseEnter={
                      !onRowHover ? undefined : () => onRowHover.enter(row)
                    }
                    onMouseLeave={
                      !onRowHover ? undefined : () => onRowHover.exit(row)
                    }
                  >
                    {selectionProps && (
                      <TableCell key="checkbox" sx={{ paddingX: '0' }}>
                        <ToggleItemCheckbox
                          selectionProps={selectionProps}
                          id={String(getRowId(row))}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      return (
                        <DataGridCell
                          key={column.field}
                          column={column}
                          row={row}
                        />
                      );
                    })}
                    {rowCallToAction && (
                      <TableCell data-type="call-to-action" align="right">
                        <Box pr={0.5}>
                          {typeof rowCallToAction === 'function'
                            ? rowCallToAction(row)
                            : rowCallToAction}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <TablePaginationFooter
            totalRowCount={totalRowCount}
            paginationProps={paginationProps}
          />
        </TableContainer>
      )}
    </Box>
  );
}
