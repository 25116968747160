import React, { useContext, useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ContractSummary } from '@octopus/api';

import { AppContext } from '../../../../../app/context';
import VirtualizedAutocomplete from '../../../VirtualizedAutocomplete';

import {
  changeDepartmentForContract,
  pollUntilContractDepartmentIsUpdated,
} from './changeDepartmentForContracts';

export type DepartmentMoveContractDialogProps = {
  open: boolean;
  onClose: () => void;
  onMoved: () => void;
  contract: ContractSummary | undefined;
};

export function DepartmentMoveContractDialog({
  open,
  onClose,
  onMoved,
  contract,
}: DepartmentMoveContractDialogProps) {
  const [scene, setScene] = useState<number>(0);
  const [newDepartment, setNewDepartment] = useState<string | null>(null);
  const [effectiveDate, setEffectiveDate] = useState<string | null>(
    dayjs().format('YYYY-MM-DD'),
  );

  useEffect(() => {
    setScene(0);
    setNewDepartment(null);
    setEffectiveDate(dayjs().format('YYYY-MM-DD'));
  }, [open]);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      await changeDepartmentForContract(
        contract.organizationId,
        contract,
        newDepartment,
        effectiveDate,
      );
      await pollUntilContractDepartmentIsUpdated(
        contract.organizationId,
        contract.contractId,
        newDepartment,
      );
    },
  });

  if (contract === undefined) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography mb={1} variant="h5" fontWeight={700}>
          Mover para outro departamento
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minHeight: '205px' }}>
        {scene === 0 && (
          <SelectDepartmentAndDateContent
            currentDepartmentId={contract.departmentId}
            newDepartment={newDepartment}
            effectiveDate={effectiveDate}
            setNewDepartment={setNewDepartment}
            setEffectiveDate={setEffectiveDate}
          />
        )}
        {scene === 1 && <ConfirmChanges />}
      </DialogContent>
      <DialogActions>
        <Button
          color="primaryAlt"
          size="large"
          variant="outlined"
          onClick={onClose}
          sx={{
            minWidth: 120,
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          color="primaryAlt"
          size="large"
          variant="contained"
          disabled={!newDepartment || !effectiveDate}
          loading={isLoading}
          sx={{
            minWidth: 120,
          }}
          onClick={() => {
            if (scene === 0) {
              setScene(1);
            } else {
              mutate(undefined, {
                onSuccess: onMoved,
              });
            }
          }}
        >
          Mudar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function SelectDepartmentAndDateContent({
  currentDepartmentId,
  newDepartment,
  effectiveDate,
  setNewDepartment,
  setEffectiveDate,
}: {
  currentDepartmentId: string | undefined;
  newDepartment: string | null;
  effectiveDate: string | null;
  setNewDepartment: (newDepartment: string | null) => void;
  setEffectiveDate: (effectiveDate: string | null) => void;
}) {
  const { appContext } = useContext(AppContext);

  const minDate = dayjs().startOf('month').subtract(1, 'month');
  const maxDate = dayjs().startOf('month').add(1, 'month').endOf('month');

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2">Qual o novo departamento?</Typography>
        <VirtualizedAutocomplete
          placeholder="Selecione o departamento"
          value={newDepartment}
          onChange={(val) => setNewDepartment(val as string | null)}
          options={[
            { value: null, label: 'Selecione um departamento' },
            ...(appContext?.company?.departments
              ?.filter((d) => d.id !== currentDepartmentId)
              ?.map((d) => ({
                value: d.id,
                label: d.name,
              })) ?? []),
          ]}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="body2">
          A mudança deve começar a ter efeito a partir de qual data?
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            defaultValue={dayjs(effectiveDate)}
            sx={{ width: '100%' }}
            onChange={(v) => {
              if (v.isBefore(minDate)) {
                setEffectiveDate(null);
              } else if (v.isAfter(maxDate)) {
                setEffectiveDate(null);
              } else {
                setEffectiveDate(v.format('YYYY-MM-DD'));
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

function ConfirmChanges() {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="body1" fontWeight="700">
        Ao confirmar, o que acontece em seguida:
      </Typography>
      <List sx={{ py: 0, px: 2, listStyleType: 'disc' }}>
        <ListItem disableGutters sx={{ pb: 2, display: 'list-item' }}>
          <Box>
            <Typography variant="body1" fontWeight="500">
              Transferência e atualização dos dados
            </Typography>
            <Typography variant="caption">
              A pessoas selecionada será transferidas e vai ter seu departamento
              atualizado a partir da data selecionada.
            </Typography>
          </Box>
        </ListItem>
        <ListItem disableGutters sx={{ pb: 2, display: 'list-item' }}>
          <Box>
            <Typography variant="body1" fontWeight="500">
              Aplicação em novos relatórios
            </Typography>
            <Typography variant="caption">
              As novas informações serão consideradas apenas para novos
              relatórios, sem impactar relatórios anteriores à data selecionada.
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
}
