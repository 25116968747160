import React, { useContext, useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Skeleton, Typography } from '@mui/material';

import {
  CompanyList,
  CompanySummary,
  JobTitleEntry,
  JobTitleList,
  JobTitleSummary,
  fetchGetAllJobTitles,
  useGetAllJobTitles,
  useSearchAllContracts,
} from '@octopus/api';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ContentTabs } from '../../modules/components/ContentTabs';
import { ExpandableTypography } from '../../modules/components/ExpandableTypography';
import { CreateJobTitleDrawer } from '../../modules/components/jobTitles/CreateJobTitleDrawer';
import { JobTitleDetailsDrawer } from '../../modules/components/jobTitles/JobTitleDetailsDrawer';
import { ArrowNavigation } from '../../modules/components/Navigator';
import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { SnackbarType } from '../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import { QueryResult } from '../../modules/types';
import { pollUntil } from '../../utils';
import { AppContext } from '../context';

export type JobTitlesContentProps = {
  organizationId: string;
  companiesQuery: QueryResult<CompanyList>;
};

const jobTitleStatuses = {
  active: 'active',
  deactivated: 'deactivated',
} as const;

type JobTitleStatus = keyof typeof jobTitleStatuses;

export function JobTitlesContent({
  organizationId,
  companiesQuery,
}: JobTitlesContentProps) {
  const { refetchCompanyData } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');

  const [showJobTitleCreation, setShowJobTitleCreation] = useState(false);
  const [isLoadingNewData, setIsLoadingNewData] = useState(false);

  const [selectedJobTitle, setSelectedJobTitle] = useState<
    JobTitleSummary | undefined
  >();

  const [jobTitlesStatus, setJobTitlesStatus] = useState<JobTitleStatus>(
    jobTitleStatuses.active,
  );

  const prepareQueryParams = (
    datagridProps: DataGridProps,
    active: boolean,
  ) => ({
    page: `${datagridProps.paginationProps.page}`,
    size: `${datagridProps.paginationProps.rowsPerPage}`,
    elementFilters: JSON.stringify({
      active: [`${active}`],
    }),
    sortField: datagridProps.sortingProps.field,
    sortOrder: datagridProps.sortingProps.order,
    ...(searchTerm.length > 0 && {
      query: searchTerm,
    }),
  });

  const activeJobTitlesDatagridProps = useDataGrid({
    key: `active-job-titles-${organizationId}`,
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const activeJobTitlesQuery = useGetAllJobTitles(
    {
      pathParams: {
        organizationId,
      },
      queryParams: prepareQueryParams(activeJobTitlesDatagridProps, true),
    },
    {
      enabled: !!organizationId,
    },
  );
  const inactiveJobTitlesDatagridProps = useDataGrid({
    key: `inactive-job-titles-${organizationId}`,
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const inactiveJobTitlesQuery = useGetAllJobTitles(
    {
      pathParams: {
        organizationId,
      },
      queryParams: prepareQueryParams(inactiveJobTitlesDatagridProps, false),
    },
    {
      enabled: !!organizationId,
    },
  );

  const datagridProps =
    jobTitlesStatus === jobTitleStatuses.active
      ? activeJobTitlesDatagridProps
      : inactiveJobTitlesDatagridProps;
  const jobTitlesQuery =
    jobTitlesStatus === jobTitleStatuses.active
      ? activeJobTitlesQuery
      : inactiveJobTitlesQuery;
  const maxCode = (jobTitlesQuery.data as any)?.metadata?.max?.code as
    | number
    | undefined;

  const refetch = async () => {
    await Promise.all([
      activeJobTitlesQuery.refetch(),
      inactiveJobTitlesQuery.refetch(),
      refetchCompanyData(),
    ]);
  };

  const contractsQuery = useSearchAllContracts();
  const jobTitleIds =
    jobTitlesQuery?.data?.data?.map(({ jobTitleId }) => jobTitleId) ?? [];
  const jobTitleIdsString = jobTitleIds.join('/');
  useEffect(() => {
    if (organizationId && jobTitleIds.length > 0) {
      contractsQuery.mutate({
        pathParams: {
          organizationId,
        },
        body: {
          pagination: {
            size: 1,
          },
          filtering: {
            elements: {
              status: [{ not: 'terminated' }],
            },
          },
          counting: {
            filtered: {
              byProp: {
                title: jobTitleIds,
              },
            },
          },
        },
      });
    }
  }, [organizationId, jobTitleIdsString]);

  const contractsByJobTitle =
    contractsQuery?.data?.metadata?.filtered?.counters?.byProp?.title;

  return (
    <PageContainer toolbar={false}>
      <PageTitle title="Cargos" dataTestId="job-titles-page-title" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        mt={1}
      >
        <DataGridToolbar
          searchPlaceholder="Procurar"
          hideFilters={true}
          filters={[]}
          searchProps={{ searchTerm, setSearchTerm }}
          filteringProps={datagridProps.filteringProps}
        />
        <Button
          size="large"
          color="primaryAlt"
          onClick={() => setShowJobTitleCreation(true)}
          data-testid="job-titles-create-button"
        >
          Novo cargo
        </Button>
      </Box>
      <ContentTabs
        selected={jobTitlesStatus}
        setSelected={setJobTitlesStatus}
        tabs={[
          {
            label: 'Ativos',
            value: jobTitleStatuses.active,
            count: activeJobTitlesQuery.data?.total,
          },
          {
            label: 'Desativados',
            value: jobTitleStatuses.deactivated,
            hidden: inactiveJobTitlesQuery.data?.total === 0,
            count: inactiveJobTitlesQuery.data?.total,
          },
        ]}
      />
      <Table
        datagridProps={datagridProps}
        isLoading={jobTitlesQuery.isLoading || isLoadingNewData}
        jobTitlesQuery={jobTitlesQuery}
        contractsByJobTitle={contractsByJobTitle}
        onRowClick={(row) => {
          setSelectedJobTitle(row);
        }}
      />
      <Creation
        organizationId={organizationId}
        companies={companiesQuery?.data?.data}
        maxCode={maxCode}
        open={showJobTitleCreation}
        onClose={() => setShowJobTitleCreation(false)}
        setLoading={setIsLoadingNewData}
        refetch={refetch}
      />
      <Details
        organizationId={organizationId}
        companies={companiesQuery?.data?.data}
        selectedJobTitle={selectedJobTitle}
        setSelectedJobTitle={setSelectedJobTitle}
        setLoading={setIsLoadingNewData}
        jobTitles={jobTitlesQuery.data}
        refetch={refetch}
        contractsByJobTitle={contractsByJobTitle}
      />
    </PageContainer>
  );
}

function Table({
  datagridProps,
  isLoading,
  jobTitlesQuery,
  contractsByJobTitle,
  onRowClick,
}: {
  datagridProps: DataGridProps;
  isLoading: boolean;
  jobTitlesQuery: QueryResult<JobTitleList>;
  contractsByJobTitle: Record<string, number> | undefined;
  onRowClick: (row: JobTitleSummary) => void;
}) {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" gap="8px" pt={1}>
        <Skeleton
          variant="rounded"
          height={45 + datagridProps.paginationProps.rowsPerPage * 58}
          width="100%"
        />
      </Box>
    );
  }

  const columns: GridColDef<JobTitleSummary>[] = [
    {
      field: 'code',
      headerName: 'Código',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.code;
      },
      renderCell: ({ value }) => {
        return <Typography variant="body2">{value}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Cargo',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.name;
      },
      renderCell: ({ value }) => {
        return (
          <ExpandableTypography
            expandTextOnHover
            variant="body2"
            sx={{ '--ExpandableTypography-max-width': '20em' }}
          >
            {value}
          </ExpandableTypography>
        );
      },
    },
    {
      field: 'contractTypes',
      headerName: 'Atribuição',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.contractTypes;
      },
      renderCell: ({ value }) => {
        let content;
        if (!value || (value.includes('br:clt') && value.includes('br:pj'))) {
          content = 'Todos';
        } else {
          if (value.includes('br:clt')) {
            content = 'Colaborador';
          } else {
            content = 'Prestador de serviço';
          }
        }
        return <Typography variant="body2">{content}</Typography>;
      },
    },
    {
      field: 'jobTitleCount',
      headerName: 'Contratos',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.jobTitleId;
      },
      renderCell: ({ value }) => {
        if (!contractsByJobTitle) {
          return <Skeleton variant="text" width="50px" />;
        }
        return (
          <Typography variant="body2">
            {contractsByJobTitle[value] ?? 0}
          </Typography>
        );
      },
    },
  ];

  return (
    <DataGrid
      getRowSx={() => ({
        height: '56px',
      })}
      sortingProps={datagridProps.sortingProps}
      paginationProps={datagridProps.paginationProps}
      totalRowCount={jobTitlesQuery.data.total || 0}
      getRowId={(row) => row.jobTitleId}
      rows={jobTitlesQuery.data.data}
      columns={columns}
      onRowClick={({ row }) => onRowClick(row)}
    />
  );
}

function Creation({
  organizationId,
  companies,
  maxCode,
  open,
  onClose,
  setLoading,
  refetch,
}: {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  maxCode: number | undefined;
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  refetch: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();

  const onSuccess = async ({ jobTitleId }: JobTitleEntry) =>
    handleUpdatesToList({
      organizationId,
      jobTitleId,
      setLoading,
      closeDrawer: onClose,
      showSnackbar,
      snackbarMessage: 'Cargo criado',
      refetch,
    });

  return (
    <CreateJobTitleDrawer
      organizationId={organizationId}
      companies={companies}
      maxCode={maxCode}
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
}

function Details({
  organizationId,
  companies,
  selectedJobTitle,
  setSelectedJobTitle,
  setLoading,
  jobTitles,
  refetch,
  contractsByJobTitle,
}: {
  organizationId: string;
  companies: CompanySummary[] | undefined;
  selectedJobTitle: JobTitleSummary | undefined;
  setSelectedJobTitle: (jobTitle: JobTitleSummary | undefined) => void;
  setLoading: (loading: boolean) => void;
  jobTitles: JobTitleList | undefined;
  refetch: () => Promise<void>;
  contractsByJobTitle: Record<string, number> | undefined;
}) {
  const { showSnackbar } = useSnackbar();
  const [detailsNavigation, setDetailsNavigation] = useState<ArrowNavigation>({
    canGoForward: false,
    canGoBackwards: false,
    goForward: undefined,
    goBackwards: undefined,
  });

  useEffect(() => {
    if (selectedJobTitle !== undefined) {
      const currentIndex = jobTitles?.data?.findIndex(
        ({ jobTitleId }) => selectedJobTitle.jobTitleId === jobTitleId,
      );
      if (currentIndex !== undefined) {
        setDetailsNavigation({
          canGoForward: currentIndex < jobTitles?.data?.length - 1,
          canGoBackwards: currentIndex > 0,
          goForward: () =>
            setSelectedJobTitle(jobTitles?.data[currentIndex + 1]),
          goBackwards: () =>
            setSelectedJobTitle(jobTitles?.data[currentIndex - 1]),
        });
        return;
      }
    }
    setDetailsNavigation({
      canGoForward: false,
      canGoBackwards: false,
      goForward: undefined,
      goBackwards: undefined,
    });
  }, [selectedJobTitle]);

  const handleUpdates = async (
    jobTitleId: string,
    message: string,
    filters: Record<string, string[]>,
  ) =>
    handleUpdatesToList({
      organizationId,
      jobTitleId,
      setLoading,
      closeDrawer: () => setSelectedJobTitle(undefined),
      showSnackbar,
      snackbarMessage: message,
      filters,
      refetch,
    });

  const onEdit = (jobTitle: JobTitleEntry) =>
    handleUpdates(jobTitle.jobTitleId, 'Cargo atualizado', {
      version: [`${jobTitle.version}`],
    });

  const onRestore = (jobTitleId: string) =>
    handleUpdates(jobTitleId, 'Cargo reativado', {
      jobTitleId: [jobTitleId],
      active: ['true'],
    });

  const onArchive = (jobTitleId: string) =>
    handleUpdates(jobTitleId, 'Cargo desativado', {
      jobTitleId: [jobTitleId],
      active: ['false'],
    });

  return (
    <JobTitleDetailsDrawer
      organizationId={organizationId}
      companies={companies}
      jobTitleSummary={selectedJobTitle}
      navigation={detailsNavigation}
      contractsCount={contractsByJobTitle?.[selectedJobTitle?.jobTitleId] ?? 0}
      open={selectedJobTitle !== undefined}
      onEdit={onEdit}
      onRestore={onRestore}
      onArchive={onArchive}
      onClose={() => setSelectedJobTitle(undefined)}
    />
  );
}

async function handleUpdatesToList({
  organizationId,
  jobTitleId,
  setLoading,
  closeDrawer,
  showSnackbar,
  snackbarMessage,
  filters,
  refetch,
}: {
  organizationId: string;
  jobTitleId: string;
  setLoading: (loading: boolean) => void;
  closeDrawer: () => void;
  showSnackbar: (snackbar: SnackbarType) => void;
  snackbarMessage: string;
  filters?: Record<string, string[]>;
  refetch: () => Promise<unknown>;
}) {
  setLoading(true);
  closeDrawer();
  showSnackbar({
    isOpen: true,
    variant: 'default',
    Message: snackbarMessage,
    StartAdornment: <CheckCircleIcon />,
    hasCloseAction: true,
  });
  try {
    await pollUntil({
      action: () =>
        fetchGetAllJobTitles({
          pathParams: {
            organizationId,
          },
          queryParams: {
            elementFilters: JSON.stringify({
              jobTitleId: [jobTitleId],
              ...(filters ?? {}),
            }),
          },
        }),
      assertion: (list) => list.data.length === 1,
      intervalMillis: 500,
      timeoutSeconds: 5,
    });
  } catch (_) {
    console.warn('Failed to poll updates to list');
  }
  await refetch();
  setLoading(false);
}
