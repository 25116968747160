import { Box, Container, Toolbar } from '@mui/material';

export const PageContainer = ({
  children,
  toolbar = true,
  width,
}: {
  children: React.ReactNode;
  toolbar?: boolean;
  width?: string;
}) => {
  return (
    <Box sx={{ backgroundColor: 'background.paper', height: '100%', width }}>
      {toolbar && <Toolbar />}
      <Container>{children}</Container>
    </Box>
  );
};
