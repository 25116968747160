import React from 'react';

import { IconArchive } from '@tabler/icons-react';

import { Box, Button, ListItem, Typography } from '@mui/material';

import { CompanyList, CostCenterEntry } from '@octopus/api';

export type CostCenterDataPros = {
  data: CostCenterEntry;
  companies: CompanyList | undefined;
  startEditing?: () => void;
};

export function CostCenterData({
  data,
  companies,
  startEditing,
}: CostCenterDataPros) {
  return (
    <Box data-testid="cost-center-data">
      {!data.active && (
        <Box display="flex" px={1.5} py={0.5}>
          <Box
            display="flex"
            alignItems="center"
            gap={0.5}
            bgcolor="background.error"
            px={1}
            py={0.5}
            borderRadius={0.5}
          >
            <IconArchive className="error main" size="16" />
            <Typography color="error.main" variant="body2" fontWeight="500">
              Desativado
            </Typography>
          </Box>
        </Box>
      )}
      <Box display="flex">
        <Box
          display="flex"
          flexDirection="column"
          gap={0.25}
          p={1.5}
          flex="1 1 0"
        >
          <Typography variant="caption" fontWeight="700">
            Código
          </Typography>
          <Typography variant="body2" fontWeight="400">
            {data.code ?? '-'}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={0.25}
          p={1.5}
          flex="5 1 0"
        >
          <Typography variant="caption" fontWeight="700">
            Nome
          </Typography>
          <Typography variant="body2" fontWeight="400">
            {data.name}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
        <Typography variant="caption" fontWeight="700">
          Tipo do centro de custo
        </Typography>
        <Typography variant="body2" fontWeight="400">
          {data.costCenterType === 'expense' ? 'Despesa' : 'Custo'}
        </Typography>
      </Box>
      {companies?.data?.length > 1 && (
        <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
          <Typography variant="caption" fontWeight="700">
            Empresas que podem usar este centro de custo
          </Typography>
          <Typography
            variant="body2"
            fontWeight="400"
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
            }}
          >
            {!data.enabledForCompanies ||
            data.enabledForCompanies.length === companies?.data?.length
              ? 'Todas'
              : data.enabledForCompanies.map((companyId) => {
                  return (
                    <ListItem
                      sx={{
                        listStyleType: 'disc',
                        display: 'list-item',
                        p: 0.5,
                      }}
                    >
                      {companies?.data?.find(
                        (company) => company.companyId === companyId,
                      )?.name ?? '-'}
                    </ListItem>
                  );
                })}
          </Typography>
        </Box>
      )}
      {data.description && (
        <Box display="flex" flexDirection="column" gap={0.25} p={1.5}>
          <Typography variant="caption" fontWeight="700">
            Descrição
          </Typography>
          <Typography variant="body2" fontWeight="400">
            {data.description}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" width="100%" pt={3}>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          sx={{
            width: '120px',
            height: '40px',
          }}
          onClick={startEditing}
          data-testid="cost-center-edit-button"
        >
          Editar
        </Button>
      </Box>
    </Box>
  );
}
