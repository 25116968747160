import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
} from '@octopus/api';
import { UnidadeSalarioFixo } from '@octopus/esocial/mapper';
import { z } from '@octopus/i18n';
import {
  BrasilAmountType,
  NEW_UI_TYPE,
  UI_TYPE,
  cnpjSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  CompanyContext,
  OrganizationContext,
} from '../../../../../modules/types';
import { TFFlags } from '../../../../fflags';
import {
  getDuracaoFields,
  getProfissionalFields,
  getRegimeFields,
} from '../fieldDefinitions';
import { getCriacaoUsuarioFields } from '../fieldDefinitions/criacaoUsuario';
import { getJornadaFields } from '../fieldDefinitions/jornada';
import { getModalidadeContratoFields } from '../fieldDefinitions/modalidadeContrato';
import {
  getOptionsFromCompanyContext,
  getOptionsFromMapper,
} from '../inputUtils';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  AdmissionIds,
  ENABLED_UNIDADE_SALARIO_FIXO,
  FormStepDefinition,
} from '../types';

import * as common from './common';
import { getSummaryStep } from './common';
import { IFormSteps } from './types';

const getAdminSteps = async ({
  admissionIds,
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  fflags,
}: {
  admissionIds: AdmissionIds;
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  fflags: TFFlags;
}): Promise<AdmissionFormSteps> => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities ||
    !companyContext.companies
  ) {
    return Promise.resolve({});
  }

  const {
    admissionType,
    admissionDate,
    tpContr,
    cnpjSindCategProf,
    salary,
    unidadeBaseCalculo,
  } = formState;

  const modalidade_contrato = getModalidadeContratoFields({
    companyContext,
    formState,
    fflags,
    modalidadeFormSteps: formSteps[admissionDraftFormSteps.modalidade_contrato],
  });

  const criacao_usuario = getCriacaoUsuarioFields({
    formState,
    criacaoUsuarioFormSteps: formSteps[admissionDraftFormSteps.criacao_usuario],
    pjEnabled: fflags.pjAdmissionEnabled.enabled,
  });

  const profissional = await getProfissionalFields({
    companyContext,
    admissionIds,
    formState,
    profissionalFormSteps: formSteps[admissionDraftFormSteps.profissional],
  });

  const regime_trabalho = getRegimeFields({
    formState,
    regimeFormSteps: formSteps[admissionDraftFormSteps.regime_trabalho],
  });

  const jornada = getJornadaFields({
    formState,
    jornadaFormSteps: formSteps[admissionDraftFormSteps.jornada],
    hideJornadaFields: false,
  });

  const duracao = getDuracaoFields({
    tpContr,
    admissionDate,
    duracaoFormSteps: formSteps[admissionDraftFormSteps.duracao],
  });

  const sindicato = {
    definition: [
      {
        label: 'CNPJ do sindicato',
        type: cnpjSchema,
        name: 'cnpjSindCategProf',
        uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
        placeholder: 'Selecione um sindicato',
        options: getOptionsFromCompanyContext({
          baseContext: companyContext?.sindicatos,
          currentValue: cnpjSindCategProf,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.sindicato,
      title: 'Sindicato',
      completed: formSteps[admissionDraftFormSteps.sindicato]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.sindicato]?.reviewed,
    },
  };

  const remuneracao = {
    definition: [
      {
        label: 'Salário base',
        type: BrasilAmountType,
        name: 'salary',
        uiType: UI_TYPE.TEXT_MONEY,
        placeholder: '0,00',
        value: salary || '',
      },
      {
        label: 'Unidade para base de cálculo',
        type: z.number().int(),
        name: 'unidadeBaseCalculo',
        uiType: UI_TYPE.SELECT,
        placeholder: 'Selecione o tipo de salário',
        options: getOptionsFromMapper({
          mapper: UnidadeSalarioFixo,
          currentValue: unidadeBaseCalculo,
          enabledKeys: ENABLED_UNIDADE_SALARIO_FIXO,
          selectIfOnlyOne: true,
        }),
      },
    ],
    options: {
      id: admissionDraftFormSteps.remuneracao,
      title: 'Remuneração',
      completed: formSteps[admissionDraftFormSteps.remuneracao]?.completed,
      reviewed: formSteps[admissionDraftFormSteps.remuneracao]?.reviewed,
    },
  };

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
  });

  const { custom_fields_admin } = await common.getAdminSteps({
    admissionIds,
    formState,
    formSteps,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    regime_trabalho,
    duracao,
    jornada,
    sindicato,
    remuneracao,
    ...(custom_fields_admin && { custom_fields_admin }),
    ...(envio_convite && { envio_convite }),
  };
};

export const getWorkerSteps = async ({
  admissionIds,
  formState,
  formSteps,
  finalizaAdmissao,
  organizationContext,
}: {
  admissionIds: AdmissionIds;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
  organizationContext: OrganizationContext;
}): Promise<AdmissionFormSteps> => {
  return common.getWorkerSteps({
    admissionIds,
    formState,
    formSteps,
    finalizaAdmissao,
    isPj: false,
    organizationContext,
  });
};

export const cltTsv = {
  getAdminSteps,
  getWorkerSteps,
} as IFormSteps;
