export type ExtraFields = {
  jobTitleName: string;
  todayInString: string;
  companyName: string;
  companyRazaoSocial: string;
  companyAddress: string;
  companyCity: string;
  organizationName: string;
  enderecoCompleto: string;
  salarioPorExtenso: string;
  admissionDateInString: string;
  ctps: string;
  ctpsSeries: string;
  paisNascimento: string;
};

export const ExtraFieldLabels = {
  jobTitleName: 'Cargo',
  todayInString: 'Data de hoje (por extenso)',
  companyName: 'Nome da empresa (company)',
  companyRazaoSocial: 'Razão social da empresa',
  companyAddress: 'Endereço da empresa',
  companyCity: 'Cidade da empresa',
  organizationName: 'Nome da organização (organization)',
  enderecoCompleto: 'Endereço completo',
  salarioPorExtenso: 'Salário (por extenso)',
  admissionDateInString: 'Data de admissão (por extenso)',
  ctps: 'Carteira de Trabalho e Previdência Social (CTPS)',
  ctpsSeries: 'Série da CTPS',
  paisNascimento: 'País de nascimento (por extenso)',
};

export const EXTRA_FIELDS_PREFIX = 'extraField';
