import { cnpj, cpf } from 'cpf-cnpj-validator';
import extenso from 'extenso';

import { ContractBRCltAddress } from '@octopus/api';
import { Municipios, TipoLogradouro } from '@octopus/esocial/mapper';

export function formatEnderecoCompleto(
  endereco: ContractBRCltAddress | undefined,
): string | undefined {
  if (!endereco) {
    return '';
  }

  const municipioPorExtenso = Municipios.getByCode(endereco?.codMunic);
  const tipoLogradouro = TipoLogradouro.getByCode(endereco?.tpLograd);

  const logradouro = `${tipoLogradouro} ${endereco?.dscLograd}, ${endereco?.nrLograd}`;
  const complemento = endereco?.complemento ? `, ${endereco?.complemento}` : '';
  const cep = `CEP ${formatCEP(endereco?.cep)}`;
  const enderecoCompleto = `${logradouro}${complemento} - ${endereco?.bairro} - ${cep} - ${municipioPorExtenso} (${endereco?.uf})`;
  return enderecoCompleto;
}

export function formatSalarioPorExtenso(
  salario: number | undefined,
): string | undefined {
  if (!salario) {
    return '';
  }

  return extenso(salario, {
    mode: 'currency',
    currency: { type: 'BRL' },
    locale: 'br',
  });
}

export function formatCPF(value: string | undefined): string | undefined {
  if (!value) {
    return undefined;
  }
  const result = cpf.format(value);
  if (!result) {
    return value;
  }
  return result;
}

export function formatCNPJ(value: string | undefined): string | undefined {
  if (!value) {
    return undefined;
  }
  const result = cnpj.format(value);
  if (!result) {
    return value;
  }
  return result;
}

export function formatCEP(
  cep: string | undefined,
  { force = false }: { force?: boolean } = {},
): string | undefined {
  if (!cep) {
    return undefined;
  }
  if (cep.length !== 8) {
    return force
      ? `${cep.slice(0, 5).padEnd(5, '0')}-${cep.slice(5).padEnd(3, '0')}`
      : cep;
  }
  return `${cep.slice(0, 5)}-${cep.slice(5)}`;
}

export function formatCNAE(cnae: string | undefined): string | undefined {
  if (!cnae) {
    return undefined;
  }
  if (cnae.length !== 7) {
    return cnae;
  }

  return cnae.substring(0, 4) + '-' + cnae.charAt(4) + '/' + cnae.substring(5);
}

export function formatCBO(cbo: string | undefined): string | undefined {
  if (!cbo) {
    return undefined;
  }
  if (cbo.length === 7 && cbo.charAt(4) === '-') {
    return cbo;
  }
  if (cbo.length !== 6) {
    return cbo;
  }
  return cbo.substring(0, 4) + '-' + cbo.substring(4);
}

export function formatPhoneBR(phone: string | undefined): string | undefined {
  if (!phone) {
    return undefined;
  }
  const result =
    phone.length === 11
      ? phone.match(/(\d{2})(\d{5})(\d{4})/)
      : phone.match(/(\d{2})(\d{4})(\d{4})/);
  if (!result) {
    return phone;
  }
  return `(${result[1]}) ${result[2]}-${result[3]}`;
}

export function formatBooleanBR(
  value: string | boolean | undefined,
): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' ? 'Sim' : 'Não';
  }
  return value ? 'Sim' : 'Não';
}
