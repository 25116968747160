import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CheckCircle, Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

import { SnackbarType } from '../../hooks/snackbarContext';
import { useSnackbar } from '../../hooks/useSnackbar';

type TemplateSummary = {
  templateId: string;
  name: string;
  category: string;
  active: boolean;
};

type AdmissionDraftEntry = {
  draftId: string;
  [key: string]: any;
};

const errorSnackbar: SnackbarType = {
  isOpen: true,
  variant: 'error',
  Message: 'Ocorreu um erro, por favor tente novamente.',
  hasCloseAction: true,
};

const getSuccessSnackbar = (message: string): SnackbarType => ({
  isOpen: true,
  variant: 'default',
  Message: message,
  StartAdornment: <CheckCircle />,
  autoHideDuration: 5000,
  hasCloseAction: false,
});

export function DeleteConfirmationDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };
  const [loading, setLoading] = useState(false);

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h2'}>
          Excluir Candidato
        </Typography>
        <Typography variant={'body1'}>
          Ao excluir o candidato do processo, os acessos a admissão da
          organização serão revogados.
          <Typography display={'inline'} color={'#BC1A41'} fontWeight={700}>
            {' '}
            Essa ação não pode ser revertida.
          </Typography>
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-archive-admission-button"
        disabled={loading}
      >
        Cancelar
      </Button>
      <LoadingButton
        loading={loading}
        color={'error'}
        variant="contained"
        size="large"
        onClick={() => {
          setLoading(true);
          action()
            .then(() => {
              showSnackbar(
                getSuccessSnackbar('O candidato foi excluído do processo'),
              );
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Excluir Candidato
      </LoadingButton>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '300px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        setLoading={setLoading}
      />
    </>
  );
}

export function AdminTakeOverConfirmationDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: () => Promise<AdmissionDraftEntry>;
}) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };

  const [loading, setLoading] = useState(false);

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h2'}>
          Preencher para o candidato
        </Typography>
        <Typography variant={'body1'}>
          Ao assumir o preenchimento pelo candidato, o acesso dele ao aplicativo
          de admissão será interrompido. Certifique-se de que o candidato foi
          comunicado sobre esta decisão antes de prosseguir.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <LoadingButton
        disabled={loading}
        variant="outlined"
        color="secondary"
        sx={{
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-archive-admission-button"
      >
        Cancelar
      </LoadingButton>
      <LoadingButton
        loading={loading}
        color="primaryAlt"
        variant="contained"
        size="large"
        onClick={() => {
          setLoading(true);
          action()
            .then((draft) => {
              showSnackbar(
                getSuccessSnackbar(
                  'Mudança realizada com sucesso! Redirecionando...',
                ),
              );
              setTimeout(() => navigate(`new/${draft.draftId}`), 2000);
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
              setOpen(false);
            });
        }}
      >
        Preencher
      </LoadingButton>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '300px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        setLoading={setLoading}
      />
    </>
  );
}

export function ResendInviteConfirmationDialog({
  open,
  setOpen,
  action,
  user_email,
  user_name,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: ({
    email,
    name,
  }: {
    email: string;
    name: string;
  }) => Promise<AdmissionDraftEntry>;
  user_email: string;
  user_name: string;
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(user_email);
  const [name, setName] = useState(user_name);
  const [formError, setFormError] = useState<{ name?: string; email?: string }>(
    {},
  );

  const { showSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    showSnackbar(
      error.message
        ? { ...errorSnackbar, Message: error.message }
        : errorSnackbar,
    );
    setLoading(false);
    console.error(error);
  };

  const closeDialog = () => {
    setOpen(false);
    setName(null);
    setEmail(null);
    setFormError({});
  };

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'stretch'}
      gap={4}
      borderRadius={2}
      p={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h2'}>
          Reenviar email
        </Typography>
        <Typography variant={'body1'}>
          Confirme as informações de email para fazer o reenvio:
        </Typography>

        <Box display="flex" flexDirection={'column'} width={'100%'} gap={1}>
          <Typography variant="caption" color="text.secondary">
            Nome do Candidato
          </Typography>
          <TextField
            disabled
            error={formError.name != null}
            fullWidth={true}
            value={name}
            required={true}
          />
          <Typography variant="caption" color="error">
            {formError.name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection={'column'} width={'100%'} gap={1}>
          <Typography variant="caption" color="text.secondary">
            Email
          </Typography>
          <TextField
            disabled
            error={formError.email != null}
            fullWidth={true}
            value={email}
            required={true}
          />
          <Typography variant="caption" color="error">
            {formError.email}
          </Typography>
        </Box>

        <Typography variant={'caption'}>
          Se o email estiver incorreto, favor solicitar a alteração no suporte
          da Tako.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <LoadingButton
        disabled={loading}
        variant="outlined"
        color="secondary"
        sx={{
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          closeDialog();
        }}
        data-testid="cancel-archive-admission-button"
      >
        Cancelar
      </LoadingButton>
      <LoadingButton
        loading={loading}
        color="primaryAlt"
        variant="contained"
        size="large"
        onClick={() => {
          if (!name) {
            setFormError({ name: 'Nome é obrigatório' });
            return;
          }
          if (!email) {
            setFormError({ email: 'Email é obrigatório' });
            return;
          }

          setLoading(true);
          action({ email, name })
            .then(() => {
              closeDialog();
              showSnackbar(getSuccessSnackbar('O candidato foi convidado'));
            })
            .catch(handleError)
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        Reenviar email
      </LoadingButton>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '300px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        setLoading={setLoading}
      />
    </>
  );
}

export function GenerateAdmissionKitDialog({
  open,
  setOpen,
  action,
  templates,
  loadingTemplates,
  templatesError,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  action: (templateIds: string[]) => Promise<void>;
  templates: TemplateSummary[];
  loadingTemplates: boolean;
  templatesError: string | null;
}) {
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<TemplateSummary[]>(
    [],
  );
  const [showNoTemplatesDialog, setShowNoTemplatesDialog] = useState(false);

  useEffect(() => {
    if (open) {
      setSelectedTemplates([]);
      if (!loadingTemplates && templates.length === 0) {
        setOpen(false);
        setShowNoTemplatesDialog(true);
      }
    }
  }, [open, templates, loadingTemplates]);

  const handleError = (error: Error) => {
    showSnackbar(errorSnackbar);
    setOpen(false);
    setLoading(false);
    console.error(error);
  };

  const handleGenerateKit = async () => {
    try {
      setLoading(true);

      const templateIds = selectedTemplates.map(
        (template) => template.templateId,
      );
      await action(templateIds);

      setOpen(false);
      showSnackbar(getSuccessSnackbar('Documento gerado, iniciando download'));
    } catch (error) {
      console.error('Erro ao gerar documentos:', error);
      handleError(
        error instanceof Error ? error : new Error('Erro desconhecido'),
      );
    } finally {
      setLoading(false);
    }
  };

  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      borderRadius={2}
      px={1.5}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
        width={'100%'}
      >
        <Typography variant={'h2'} fontWeight={700}>
          Documentos admissionais
        </Typography>

        <Box width="100%">
          <Box mb={1}>
            <Typography variant="caption" color="text.secondary">
              Selecione os documentos a serem gerados
            </Typography>
          </Box>
          <FormControl fullWidth error={!!templatesError}>
            <Autocomplete
              multiple
              id="template-select"
              options={templates}
              loading={loadingTemplates}
              disabled={loadingTemplates || templates.length === 0}
              getOptionLabel={(option) => option.name}
              value={selectedTemplates}
              onChange={(_, newValue) => {
                setSelectedTemplates(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon />}
                    size="small"
                    sx={{
                      display: 'flex',
                      minHeight: '18px',
                      maxHeight: '18px',
                      alignItems: 'center',
                      borderRadius: '100px',
                      backgroundColor: 'rgba(37, 37, 45, 0.04)',
                      '& .MuiChip-label': {
                        color: 'text.primary',
                        fontSize: '12px',
                        lineHeight: '12px',
                        letterSpacing: '0.15px',
                      },
                      '& .MuiChip-deleteIcon': {
                        fontSize: '12px',
                        fontWeight: 500,
                        color: 'text.secondary',
                        margin: '0 4px 0 -4px',
                      },
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Selecione"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingTemplates ? (
                          <CircularProgress size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                />
              )}
              noOptionsText="Nenhum template disponível"
              loadingText="Carregando templates..."
            />
            {templatesError && (
              <FormHelperText>{templatesError}</FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    </Box>
  );

  const actions = (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          px: 4,
          ':hover': {
            backgroundColor: 'strokes.light',
          },
        }}
        size="large"
        onClick={() => {
          setOpen(false);
        }}
        data-testid="cancel-generate-kit-button"
        disabled={loading}
      >
        Cancelar
      </Button>
      <LoadingButton
        loading={loading}
        color="primaryAlt"
        variant="contained"
        size="large"
        onClick={handleGenerateKit}
        disabled={
          loadingTemplates ||
          templates.length === 0 ||
          selectedTemplates.length === 0
        }
      >
        Avançar
      </LoadingButton>
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: {
            minWidth: '600px',
            maxWidth: '600px',
            minHeight: '200px',
            maxHeight: '300px',
          },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
        setLoading={setLoading}
      />
      <NoTemplatesDialog
        open={showNoTemplatesDialog}
        setOpen={setShowNoTemplatesDialog}
      />
    </>
  );
}

export function NoTemplatesDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const content = (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={4}
      borderRadius={2}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={3}
      >
        <Typography fontWeight={700} variant={'h2'}>
          Atenção
        </Typography>
        <Typography variant={'body2'} fontWeight={500}>
          Nenhum template de documento contratual foi cadastrado na plataforma.
          Para mais informações, entre em contato com a Tako.
        </Typography>
      </Box>
    </Box>
  );

  const actions = (
    <Button
      variant="contained"
      color="primaryAlt"
      sx={{ px: 4 }}
      size="large"
      onClick={() => setOpen(false)}
    >
      Entendi
    </Button>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        PaperProps={{
          sx: { minWidth: '600px', maxWidth: '600px', minHeight: '200px' },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        actions={actions}
        content={content}
      />
    </>
  );
}

export function MobileDrawer({
  content,
  actions,
  setOpen,
  setLoading,
  open,
}: {
  open: boolean;
  content: React.ReactNode;
  actions?: React.ReactNode;
  setOpen?: (open: boolean) => void;
  setLoading?: (open: boolean) => void;
  loading?: boolean;
}) {
  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={() => {
        if (setOpen) {
          setOpen(false);
          setLoading && setLoading(false);
        }
      }}
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: { display: 'none' },
        zIndex: 99999,
      })}
      transitionDuration={500}
      elevation={2}
      PaperProps={{ sx: { borderRadius: '16px 16px 0 0 ' } }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        p={2}
      >
        <Box height={'70%'}>{content}</Box>
        {actions && (
          <Box
            height={'30%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-end'}
            mt={5}
            mb={1}
            gap={2}
          >
            {actions}
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
