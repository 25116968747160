import React, { useEffect, useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import {
  CompanyList,
  CostCenterEntry,
  useGetCostCenterEntry,
} from '@octopus/api';

import { ErrorAlert } from '../../ErrorAlert';

import { CostCenterData } from './CostCenterData';
import { CostCenterEdit } from './CostCenterEdit';

export type CostCenterDetailsProps = {
  organizationId: string;
  costCenterId: string;
  companies: CompanyList | undefined;
  onEdit: (updatedEntry: CostCenterEntry) => void;
  onArchive: (costCenterId: string) => void;
  onRestore: (costCenterId: string) => void;
};

export function CostCenter({
  organizationId,
  costCenterId,
  companies,
  onEdit,
  onArchive,
  onRestore,
}: CostCenterDetailsProps) {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(false);
  }, [organizationId, costCenterId]);

  const { isLoading, isError, data, remove } = useGetCostCenterEntry(
    {
      pathParams: {
        organizationId,
        costCenterId,
      },
    },
    {
      enabled: !!organizationId && !!costCenterId,
    },
  );

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="100%" />;
  }

  if (isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <ErrorAlert
          message="Falha ao carregar centro de custo, por favor tente novamente mais tarde.
          Se o problema persistir, entre em contato com o suporte da Tako."
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      {!isEditing ? (
        <CostCenterData
          data={data}
          companies={companies}
          startEditing={() => setIsEditing(true)}
        />
      ) : (
        <CostCenterEdit
          costCenter={data}
          companies={companies}
          onEdit={(costCenter) => {
            onEdit(costCenter);
            remove();
          }}
          onArchive={(costCenterId) => {
            onArchive(costCenterId);
            remove();
          }}
          onRestore={(costCenterId) => {
            onRestore(costCenterId);
            remove();
          }}
          stopEditing={() => setIsEditing(false)}
        />
      )}
    </Box>
  );
}
