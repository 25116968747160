import React, { useState } from 'react';

import { IconArchive, IconFolderUp } from '@tabler/icons-react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import {
  CompanyList,
  DepartmentEntry,
  DepartmentInput,
  useActivateDepartment,
  useDeactivateDepartment,
  usePutDepartment,
} from '@octopus/api';
import { AppError } from '@octopus/i18n';

import { mapApiErrors, translateAPIErrors } from '../../../../utils';
import { DepartmentForm, sanitizeDepartmentInput } from '../DepartmentForm';

export type DepartmentEditProps = {
  department: DepartmentEntry;
  companies: CompanyList | undefined;
  onEdit: (updatedEntry: DepartmentEntry) => void;
  onArchive: (departmentId: string) => void;
  onRestore: (departmentId: string) => void;
  stopEditing: () => void;
};

export function DepartmentEdit({
  department,
  companies,
  onEdit,
  onArchive,
  onRestore,
  stopEditing,
}: DepartmentEditProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  return (
    <Box>
      <Edit
        department={department}
        companies={companies}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        onEdit={onEdit}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Archive
        department={department}
        showModal={showArchiveModal}
        setShowModal={setShowArchiveModal}
        onArchive={() => {
          stopEditing();
          onArchive(department.departmentId);
        }}
      />
      <Restore
        department={department}
        showModal={showRestoreModal}
        setShowModal={setShowRestoreModal}
        onRestore={() => {
          stopEditing();
          onRestore(department.departmentId);
        }}
      />
      <Box
        py={1}
        px={3}
        gap={3}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={(theme) => ({
          background: 'rgba(247, 247, 247, 0.90)',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '700px',
        })}
      >
        {department.active ? (
          <IconButton
            onClick={() => setShowArchiveModal(true)}
            sx={{
              borderRadius: '8px',
            }}
            data-testid="department-deactivate"
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ cursor: 'pointer' }}
            >
              <IconArchive className="error main" size={20} />
              <Typography color="error.main" variant="body2" fontWeight="600">
                Desativar
              </Typography>
            </Box>
          </IconButton>
        ) : (
          <IconButton
            onClick={() => setShowRestoreModal(true)}
            sx={{
              borderRadius: '8px',
            }}
            data-testid="department-activate"
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ cursor: 'pointer' }}
            >
              <IconFolderUp className="success main" size={20} />
              <Typography color="success.main" variant="body2" fontWeight="600">
                Reativar
              </Typography>
            </Box>
          </IconButton>
        )}
        <Box display="flex" gap={1}>
          <Button
            color="secondary"
            size="large"
            sx={{ width: '120px' }}
            onClick={stopEditing}
          >
            Cancelar
          </Button>
          <LoadingButton
            color="primaryAlt"
            size="large"
            variant="contained"
            sx={{ width: '120px' }}
            loading={isLoading}
            onClick={() => setShowEditModal(true)}
            data-testid="department-update-submit-button"
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

function Edit({
  department,
  companies,
  showModal,
  setShowModal,
  onEdit,
  isLoading,
  setIsLoading,
}: {
  department: DepartmentEntry;
  companies: CompanyList | undefined;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onEdit: (updatedDepartment: DepartmentEntry) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) {
  const [failure, setFailure] = React.useState<string | undefined>(undefined);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [form, setForm] = useState<DepartmentInput>({
    code: department.code,
    name: department.name,
    enabledForCompanies:
      department.enabledForCompanies ??
      companies?.data?.map(({ companyId }) => companyId) ??
      [],
    description: department.description,
  });

  const { mutate } = usePutDepartment();

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setShowModal(false);
    setFailure(undefined);
    setErrors({});
    mutate(
      {
        pathParams: {
          organizationId: department.organizationId,
          departmentId: department.departmentId,
        },
        body: {
          ...sanitizeDepartmentInput(form, companies),
          version: department.version,
        },
      },
      {
        onSuccess: onEdit,
        onError: (err: unknown) => {
          setIsLoading(false);
          const error = err as AppError;
          if (error.statusCode === 409) {
            setErrors({
              name: 'Já existe um departamento com este nome.',
            });
          } else if (error.statusCode === 412) {
            setFailure(translateAPIErrors(error as unknown as AppError)[0]);
          } else if (error.statusCode === 400) {
            setErrors(mapApiErrors(error as unknown as AppError));
          } else {
            setFailure(
              'Falha ao atualizar departamento, por favor tente novamente mais tarde.\n' +
                'Se o problema persistir, entre em contato com o suporte da Tako.',
            );
          }
        },
      },
    );
  };

  return (
    <Box data-testid="department-edit-form">
      <DepartmentForm
        companies={companies}
        form={form}
        setForm={setForm}
        failure={failure}
        errors={errors}
        clearError={(key) => {
          setErrors((current) => {
            const { [key]: _, ...rest } = current;
            return rest;
          });
        }}
        disabled={isLoading}
      />
      <EditChangeConfirmationModal
        open={showModal}
        onConfirm={handleSubmit}
        onCancel={() => setShowModal(false)}
      />
    </Box>
  );
}

function EditChangeConfirmationModal({
  open,
  onConfirm,
  onCancel,
}: {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      data-testid="department-edit-modal"
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight={700}>
          Alteração de departamento
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 2, pt: 1 }}>
          <Typography variant="body1" fontWeight="600">
            Ao confirmar a alteração, o que acontece em seguida:
          </Typography>
        </Box>
        <List
          sx={{
            py: 0,
            px: 2,
            listStyleType: 'disc',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
            <Box>
              <Typography variant="body1">Atualização dos dados</Typography>
              <Typography variant="caption">
                Todos os membros terão seus departamentos atualizados.
              </Typography>
            </Box>
          </ListItem>
          <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
            <Box>
              <Typography variant="body1">Aplicação em relatórios</Typography>
              <Typography variant="caption">
                Essas alterações impactam como os relatórios serão exibidos.
              </Typography>
            </Box>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          size="large"
          onClick={onConfirm}
          data-testid="confirm"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Archive({
  department,
  showModal,
  setShowModal,
  onArchive,
}: {
  department: DepartmentEntry;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onArchive: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useDeactivateDepartment();

  const onConfirm = () => {
    setIsLoading(true);
    mutate(
      {
        pathParams: {
          organizationId: department.organizationId,
          departmentId: department.departmentId,
        },
        body: {
          version: department.version,
        },
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          setShowModal(false);
          onArchive();
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <ActivationChangeConfirmationModal
      title="Desativar departamento"
      message={
        'O departamento listado abaixo será desativado. Você pode encontrá-lo na ' +
        'tela de Departamentos, em "Desativados".'
      }
      name={department.name}
      open={showModal}
      isLoading={isLoading}
      onConfirm={onConfirm}
      onCancel={() => setShowModal(false)}
    />
  );
}

function Restore({
  department,
  showModal,
  setShowModal,
  onRestore,
}: {
  department: DepartmentEntry;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onRestore: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useActivateDepartment();

  const onConfirm = () => {
    setIsLoading(true);
    mutate(
      {
        pathParams: {
          organizationId: department.organizationId,
          departmentId: department.departmentId,
        },
        body: {
          version: department.version,
        },
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          setShowModal(false);
          onRestore();
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <ActivationChangeConfirmationModal
      title="Reativar departamento"
      message={
        'O departamento listado abaixo será reativado. Você pode encontrá-lo na ' +
        'tela de Departamentos, em "Ativos".'
      }
      name={department.name}
      open={showModal}
      isLoading={isLoading}
      onConfirm={onConfirm}
      onCancel={() => setShowModal(false)}
    />
  );
}

function ActivationChangeConfirmationModal({
  title,
  message,
  name,
  open,
  isLoading,
  onConfirm,
  onCancel,
}: {
  title: string;
  message: string;
  name: string;
  open: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      data-testid="activation-change-confirmation-modal"
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 3 }}>
          <Typography variant="body2">{message}</Typography>
        </Box>
        <Box display="flex" gap={1.5}>
          <Typography variant="body2" color="text.secondary">
            Departamento
          </Typography>
          <Typography variant="body2">{name}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onCancel}>
          Cancelar
        </Button>
        <LoadingButton
          color="primaryAlt"
          size="large"
          onClick={onConfirm}
          loading={isLoading}
          data-testid="confirm"
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
