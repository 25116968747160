import React, { useEffect, useRef, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Badge,
  Box,
  IconButton,
  Link,
  Popover,
  Typography,
} from '@mui/material';

import { ContractEntry } from '@octopus/api';

import { Utils } from './history/utils';
import { TerminateButton } from './Terminate';

type ContractActionsProps = {
  contractEntry: ContractEntry;
  onHistorySelected: () => void;
  scheduledEvents?: number;
  refetchContract: () => void;
};

export function ContractActionsMenu({
  contractEntry,
  onHistorySelected,
  scheduledEvents,
  refetchContract,
}: ContractActionsProps) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const close = () => setOpen(false);
  const open = () => setOpen(true);
  const hasScheduledEvents = scheduledEvents > 0;
  const menuRef = useRef(null);
  const [hasBadge, setHasBadge] = useState(false);

  useEffect(() => {
    setHasBadge(scheduledEvents > 0);
  }, [scheduledEvents]);

  return (
    <>
      {hasBadge ? (
        <Badge badgeContent={scheduledEvents} color="primary">
          <IconButton
            size="small"
            onClick={open}
            sx={{ borderRadius: 1 }}
            ref={menuRef}
          >
            <MoreVertIcon />
          </IconButton>
        </Badge>
      ) : (
        <IconButton
          size="small"
          onClick={(event) => {
            event.preventDefault();
            open();
          }}
          sx={{ borderRadius: 1 }}
          ref={menuRef}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Popover
        open={isOpen}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box display-={'flex'} py={1}>
          <HistoryButton
            hasScheduledEvents={hasScheduledEvents}
            onHistorySelected={onHistorySelected}
            scheduledEvents={scheduledEvents}
            setOpen={setOpen}
          />
          <TerminateButton
            setOpen={setOpen}
            contract={contractEntry}
            refetchContract={refetchContract}
          />
        </Box>
      </Popover>
    </>
  );
}

function HistoryButton({
  setOpen,
  scheduledEvents,
  hasScheduledEvents,
  onHistorySelected,
}: {
  setOpen: (open: boolean) => void;
  scheduledEvents: number;
  hasScheduledEvents: boolean;
  onHistorySelected: () => void;
}) {
  const { hoveredStyle, hoverEventHandlers } = Utils.Hooks.useHoverBackground();

  return (
    <Box
      display="flex"
      {...hoverEventHandlers}
      sx={{
        height: '36px',
        width: !hasScheduledEvents ? '100%' : undefined,
        px: 0,
      }}
    >
      <Link
        component="button"
        underline="none"
        textAlign="left"
        sx={(theme) => ({
          ...hoveredStyle(theme),
          padding: 0,
          width: !hasScheduledEvents ? '100%' : undefined,
        })}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(false);
          onHistorySelected();
        }}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          fontSize={14}
          sx={{ px: 2 }}
        >
          Histórico
        </Typography>
      </Link>
      {hasScheduledEvents ? (
        <Link
          component="button"
          underline="none"
          textAlign="left"
          sx={(theme) => ({
            ...hoveredStyle(theme),
            width: '100%',
            padding: 0,
          })}
          onClick={(event) => {
            event.stopPropagation();
            setOpen(false);
            onHistorySelected();
          }}
        >
          <Typography variant="body2" color="primary" sx={{ px: 2 }}>
            {scheduledEvents} agendamentos
          </Typography>
        </Link>
      ) : undefined}
    </Box>
  );
}
